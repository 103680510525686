import { useLocation, useNavigate } from 'react-router-dom';
import scrollToPage from '../../utils/ScrollToLink';
import coraLogo from '../../assets/imgs/cora_logo_white.svg';
import Constants from '../../utils/Constants';

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div id='footer'>
      <div className='footer_contact'>
        <div
          className='footer_logo'
          onClick={() => scrollToPage('home', location, navigate)}
        >
          <img src={coraLogo} alt='korta logo footer' />
        </div>
        <p>
          <a
            href='https://goo.gl/maps/zSyDCk6r2FNzBCNK9'
            target='_blank'
            rel='noreferrer'
            className='opacity_transition_low'
          >
            Put Supavla 39
          </a>
        </p>
        <p>21000 Split, Croatia</p>
        <p>
          <a href='tel:+385 21 293 100' className='opacity_transition_low'>
            +385 21 293 100
          </a>
        </p>
        <p>
          <a href='tel:+385 91 293 1005' className='opacity_transition_low'>
            +385 91 293 1005
          </a>
        </p>
        <p>
          <a
            href='mailto:reservations@cora-hotel.com'
            className='opacity_transition_low'
          >
            reservations@cora-hotel.com
          </a>
        </p>
      </div>
      <div className='footer_links'>
        <div
          className='opacity_transition_high'
          onClick={() => scrollToPage(Constants.IMPRESSUM, location, navigate, true)}
        >
          IMPRESSUM
        </div>
        <div
          className='opacity_transition_high'
          onClick={() => scrollToPage(Constants.RULES, location, navigate, true)}
        >
          HOTEL RULES
        </div>
      </div>
      <div className='footer_bottom'>
        <p>Copyright © Cora {new Date().getFullYear()}.</p>
      </div>
    </div>
  );
};

export default Footer;
